export default [
  {
    id: 1,
    image: require('@/assets/question-1.png'),
    content: '請問您是否認為「混合雲」是您公司未來發展趨勢？',
    yes: 1,
    no: 0,
  },
  {
    id: 2,
    image: require('@/assets/question-2.png'),
    content: '請問您的公司是否高度重視內部的資料隱私與機密保存？',
    yes: 1,
    no: 0,
  },
  {
    id: 3,
    image: require('@/assets/question-3.png'),
    content: '請問您的公司是否將彈性維護與即時掌握IT架構視為核心營運重點？',
    yes: 1,
    no: 0
  },
  {
    id: 4,
    image: require('@/assets/question-4.png'),
    content: '請問您的公司是否已採用公有雲？',
    yes: 1,
    no: 0,
  },
  {
    id: 5,
    image: require('@/assets/question-5.png'),
    content: '請問您的公司未來是否會「同時使用」多家廠商的雲端服務？',
    yes: 1,
    no: 0,
  },
  {
    id: 6,
    image: require('@/assets/question-6.png'),
    content: '您公司的伺服器與儲存設備是否有當機或是效能不足的問題？',
    yes: 0,
    no: 1,
  },
  {
    id: 7,
    image: require('@/assets/question-7.png'),
    content: '您的公司近期是否有採購「虛擬伺服器」的相關計畫？',
    yes: 1,
    no: 0,
  },
  {
    id: 8,
    image: require('@/assets/question-8.png'),
    content: '您的公司是否曾經或正在使用 IBM Power 系列伺服器？',
    yes: 1,
    no: 0,
  },
]
