<template lang="pug">
footer#v-footer: .container
  small
    | Copyright © {{ year }} 流線傳媒有限公司 All rights reserved.
    ul
      li: a(href='https://buzzorange.com/techorange/', target='_blank') TechOrange科技報橘
      li: a(href='https://buzzorange.com/', target='_blank') BuzzOrange報橘
      li: a(href='https://buzzorange.com/vidaorange/', target='_blank') VidaOrange生活報橘
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-footer
  +flex(null, center)
  +position(absolute, null, 0, 28px, 0)
small
  +font(12px, white)
  +margin-x(auto)
  display: flex
  width: fit-content
  ul
    list-style-type: none
    display: flex
    margin-left: 1em
    li
      &:not(:last-child)::after
        content: '|'
        margin-left: .5em
        margin-right: .5em

@media (max-width: 1019px)
  #v-footer
    transform-origin: 0 50%
    transform: scale(.83)
    width: 120%
  small
    +flex(null, center, column)
    line-height: 1.5
    ul
      margin-left: 0
</style>
