<template lang="pug">
form#v-form(@submit.prevent='submit'): .container
  h2 填寫資料，下載最新《ITIC 全球伺服器關鍵洞察》白皮書！
  .fields
    .field
      label 姓*
      input(v-model='fields.lastName', :disabled='status !== "idle"', name='last-name', type='text', required)
    .field
      label 名*
      input(v-model='fields.firstName', :disabled='status !== "idle"', name='first-name', type='text', required)
    .field
      label 公司名稱*
      input(v-model='fields.company', :disabled='status !== "idle"', name='company-name', type='text', required)
    .field
      label 部門*
      input(v-model='fields.department', :disabled='status !== "idle"', name='department', type='text', required)
    .field
      label 職務名稱*
      input(v-model='fields.jobTitle', :disabled='status !== "idle"', name='job-title', type='text', required)
    .field
      label 電子信箱*
      input(v-model='fields.email', :disabled='status !== "idle"', name='office-email', type='email', required)
    .field
      label 手機號碼*
      input(v-model='fields.phone', :disabled='status !== "idle"', name='phone-number', type='tel', placeholder='0912345678', title='僅可輸入數字', pattern='[0-9]+', required)
    .field
      label 公司電話*
      input(v-model='fields.officePhone', :disabled='status !== "idle"', name='office-phone-number', type='tel', placeholder='02-2222-3333#123', title='僅可輸入數字, #, -', pattern='[0-9#-]+', required)
  .fields
    .field
      select(v-model='fields.industry', :disabled='status !== "idle"', name='industry', required)
        option(value='', selected, disabled) 產業*
        option(value='高科技製造') 高科技製造
        option(value='資訊軟體服務') 資訊軟體服務
        option(value='資訊硬體製造') 資訊硬體製造
        option(value='資訊安全') 資訊安全
        option(value='資訊系統整合') 資訊系統整合
        option(value='網路通訊') 網路通訊
        option(value='電信服務') 電信服務
        option(value='金融保險') 金融保險
        option(value='醫療生技') 醫療生技
        option(value='能源產業') 能源產業
        option(value='零售電商') 零售電商
        option(value='傳產製造') 傳產製造
        option(value='政府機關') 政府機關
        option(value='媒體行銷') 媒體行銷
        option(value='公協會/財團法人') 公協會/財團法人
        option(value='教育機構') 教育機構
        option(value='服務業') 服務業
        option(value='學生') 學生
        option(value='其他') 其他
    .field
      select(v-model='fields.companySize', :disabled='status !== "idle"', name='company-size', required)
        option(value='', disabled, selected) 公司規模*
        option(value='500人以下') 500人以下
        option(value='501-1000人') 501-1000人
        option(value='1001人以上') 1001人以上
  .contact
    p IBM得以使用我的聯絡人資料，以讓我得知產品、服務及供應方案的最新資訊
    ul
      li: label
        input(v-model='fields.contact', :disabled='status !== "idle"', name='contact', type='checkbox', value='email')
        | 透過電子郵件
      li: label
        input(v-model='fields.contact', :disabled='status !== "idle"', name='contact', type='checkbox', value='phone')
        | 透過電話
  .tip
    p
      | 您可以隨時透過提交
      |
      a(href='https://www.ibm.com/account/us-en/', target='_blank') 拒絕要求
      |
      | 來撤銷行銷許可。您也可以透過按一下每封行銷電子郵件中的取消訂閱鏈結來取消訂閱接收行銷電子郵件。有關這些處理方式的相關資訊，請參閱
      |
      a(href='https://www.ibm.com/tw-zh/privacy', target='_blank') IBM 隱私權聲明
      | 。提交此表單，即表示我確認我已閱讀並暸解IBM隱私權聲明。
  .submit
    button(:disabled='status !== "idle"', type='submit') {{ buttonWording }}
</template>

<script>
import { addIndex, map } from 'ramda'
import questions from '@/data/questions.js'
import results from '@/data/results.js'

export default {
  name: 'Form',
  data() {
    return {
      questions: questions,
      results: results,
      status: 'idle', // idle, submitting, submitted
      fields: {
        lastName: '',
        firstName: '',
        company: '',
        department: '',
        jobTitle: '',
        email: '',
        phone: '',
        officePhone: '',
        industry: '',
        companySize: '',
        contact: ['email', 'phone'],
      },
    }
  },
  computed: {
    buttonWording() {
      if(this.status === 'submitting') {
        return '請稍候...'
      } else if(this.status === 'submitted') {
        return '已送出'
      } else {
        return '送出資料'
      }
    },
    formQuestions() {
      const mapIndexed = addIndex(map)
      return mapIndexed((answer, i) => ({
        question: this.questions[i].content,
        answers: [answer],
      }), this.$store.state.answers)
    },
  },
  methods: {
    submit() {
      this.status = 'submitting'
      this.$http.post('/api/upload', {
        last_name: this.fields.lastName,
        first_name: this.fields.firstName,
        company: this.fields.company,
        department: this.fields.department,
        job_title: this.fields.jobTitle,
        email: this.fields.email,
        phone: this.fields.phone,
        company_size: this.fields.companySize,
        questionnum1_ooemail: 'Q_XSYS:OOEMAIL',
        email_verification: this.fields.contact.includes('email') ? 'NOT CHECKED' : 'CHECKED',
        questionnum2_ootele: 'Q_XSYS:OOTELE',
        phone_verification: this.fields.contact.includes('phone') ? 'NOT CHECKED' : 'CHECKED',
        form_questions: [
          {
            question: '公司電話',
            answers: [this.fields.officePhone],
          },
          {
            question: '產業',
            answers: [this.fields.industry],
          },
          ...this.formQuestions,
          {
            question: '測驗結果',
            answers: [this.results[this.$store.state.result].name],
          },
        ],
        source: this.$store.state.utm.utm_source,
        medium: this.$store.state.utm.utm_medium,
        campaign: this.$store.state.utm.utm_campaign,
        term: this.$store.state.utm.utm_term,
        content: this.$store.state.utm.utm_content,
      }).then(() => {
        this.status = 'submitted'
        this.$router.push({ name: 'result' })
      }).catch(err => {
        if(err.response.data.status === 0) {
          alert(`您的資料提交失敗\n\n${err.response.data.message}\n\n如有其他問題，請發送電子信件至 imailtw@tw.ibm.com，洽詢相關服務人員。`)
        } else {
          alert('系統忙碌中，請稍後再試')
        }
        this.status = 'idle'
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-form
  +background(url(~@/assets/form-background.jpg))
  +flex(null, center)
  +padding-y(100px, 80px)
.container
  width: 960px
h2
  +font(30px, #A7F0BA, 1.5, .05em, bold, center)
  margin-bottom: 36px
.fields
  display: grid
  grid-gap: 20px 80px
  grid-template-columns: repeat(2, 440px)
  &:not(:last-of-type)
    margin-bottom: 24px
  .field
    position: relative
    input[type='email'],
    input[type='tel'],
    input[type='text']
      +size(100%, 36px)
      background-color: transparent
      border-bottom: 1px solid white
      color: white
      padding-left: 5.5em
      transition: border-color .2s, opacity .2s
      &::placeholder
        +font(14px, lightgray, null, null, 300)
      &:-webkit-autofill
        transition: background-color 999999s 999999s, color 999999s 999999s
      &:focus
        border-color: #A7F0BA
      &:disabled
        opacity: .8
    label
      +font(16px, #A7F0BA, null, .05em)
      +position(absolute, 50%, null, null, .5em)
      pointer-events: none
      transform: translateY(-50%)
    select
      +font(14px, black, null, .05em)
      +background(url(~@/assets/dropdown-icon.png), 10px, no-repeat, right .5em center)
      +border(1px, #D6D6D6, 3px)
      +size(100%, 36px)
      transition: background-color .2s, color .2s
      &:invalid
        color: #727272
      &:disabled
        opacity: .8
.contact
  +margin-y(28px)
  p
    +font(14px, white, null, .05em)
    margin-bottom: 16px
  ul
    display: flex
    list-style-type: none
    > li
      &:not(:last-child)
        margin-right: 28px
      label
        +font(14px, white, null, .05em)
.tip
  margin-bottom: 28px
  p
    +font(14px, white, 1.5, .05em, null, justify)
    a
      color: #A7F0BA
.submit
  text-align: center
  button
    +circle(148px)
    +ifont(24px, #455CFF, null, .05em, bold)
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(191.8deg, rgba(69, 92, 255, 0.4) -8.51%, rgba(255, 255, 255, 0) 59.7%), linear-gradient(203.88deg, rgba(138, 63, 252, 0.1) 49.54%, rgba(255, 255, 255, 0) 84.66%), #455CFF
    transition: filter .3s
    &:disabled
      filter: grayscale(100%)

@media (max-width: 1019px)
  #v-form
    +padding-y(80px, 100px)
  .container
    width: 100%
  h2
    font-size: 18px
    margin-bottom: 40px
  .fields
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 12px 20px
    &:not(:last-of-type)
      margin-bottom: 20px
    .field
      &:nth-of-type(n + 3)
        grid-column: 1 / span 2
  .contact
    +margin-y(20px)
    p
      +font(12px, null, 1.5, null, null, justify)
    ul
      > li
        &:not(:last-child)
          margin-right: 20px
        label
          font-size: 12px
  .tip
    margin-bottom: 20px
    p
      font-size: 12px
  .submit
    button
      font-size: 16px
      +size(96px)
</style>
