<template lang="pug">
main#v-home: .container
  h1 2021 決戰 IT 新基建
  h2 8 題小測驗，找出您的混合多雲架構練武招式
  p
    | 面對近年來以混合雲架構為主的企業基礎架構轉型，想知道你的企業數位轉型練功派別嗎？
    br(v-if='!$store.state.mobile')
    | 立即參加測驗，找出你的企業武林門派！
  p
    | 以下8題是非題，將協助您判斷公司對於數位化的建置目標方向為何。
    br(v-if='!$store.state.mobile')
    | 只要完成測驗並填寫資料，便能獲得
    strong 《全球伺服器可靠性》武林秘笈白皮書
    | ！
  router-link(:to='{name: "question"}') 開始測驗
</template>

<script>
import VFooter from '@/components/Footer.vue'

export default {
  name: 'Home',
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-home
  +background((url(~@/assets/ball-item-1.png), url(~@/assets/ball-item-2.png), url(~@/assets/home-background.jpg)), (124px, 80px, cover), no-repeat, (left calc(50% - 500px) top 180px, left calc(50% + 500px) top 100px, center center))
  +flex(null, center)
  +padding-y(100px, 80px)
h1
  +font(60px, white, null, .05em, bold, center)
  margin-bottom: 12px
  text-shadow: 0 4px 4px rgba(black, .25)
h2
  +font(36px, #A7F0BA, null, .05em, bold, center)
  margin-bottom: 64px
  text-shadow: 0 4px 4px rgba(black, .25)
p
  +font(20px, white, 1.5, .05em, 500, center)
  +margin-x(auto)
  &:not(:last-of-type)
    margin-bottom: 20px
  &:last-of-type
    margin-bottom: 52px
  strong
    color: #A7F0BA
a
  +circle(148px)
  +flex(center, center)
  +ifont(24px, #455CFF, null, .05em, bold)
  +margin-x(auto)
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(191.8deg, rgba(69, 92, 255, 0.4) -8.51%, rgba(255, 255, 255, 0) 59.7%), linear-gradient(203.88deg, rgba(138, 63, 252, 0.1) 49.54%, rgba(255, 255, 255, 0) 84.66%), #455CFF
  filter: drop-shadow(0 4px 4px rgba(black, .25))
  &:hover
    text-decoration: none

@media (max-width: 1019px)
  #v-home
    +padding-y(80px, 100px)
  h1
    font-size: 24px
  h2
    +font(18px, null, 1.5)
    margin-bottom: 36px
  p
    +font(14px, null, null, null, null, justify)
    &:not(:last-of-type)
      margin-bottom: 16px
    &:last-of-type
      margin-bottom: 24px
  a
    +size(96px)
    font-size: 16px

</style>
