<template lang="pug">
header#v-header: router-link(:to='{name: "home"}')
  img(src='@/assets/techorange-logo.png', alt='TechOrange 科技報橘', :height='$store.state.mobile ? 16 : 28')
  img.cross(src='@/assets/cross-icon.png', alt='Crossover', :height='$store.state.mobile ? 16 : 24')
  img(src='@/assets/ibm-logo.png', alt='IBM', :height='$store.state.mobile ? 16 : 28')
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-header
  +flex(null, center)
  +position(absolute, 36px, null, null, 36px)
  z-index: 10
.cross
  +margin-x(1em)

@media (max-width: 1019px)
  #v-header
    +position(null, 20px, null, null, 30px)
  .cross
    +margin-x(.5em)
</style>
