<template lang="pug">
section#v-result(:class='`result-${result.id}`'): .container
  div
    label 您的公司門派：
    img(v-if='$store.state.mobile', :src='result.image', width='100')
    h3 {{ result.name }}
    p.description {{ result.description }}
    a.whitebook(:href='result.link', target='_blank') 多謝大俠，祕笈在此，點擊下載
    p.contact
      | 有任何疑問歡迎致電
      |
      a(href='tel:0800-016-888,1', target='_blank') 0800-016-888#1
      br(v-if='$store.state.mobile')
      |
      | 或至
      |
      a(href='https://www.ibm.com/tw-zh/it-infrastructure/power', target='_blank') IBM官網
      |
      | 瞭解更多
  img(v-if='!$store.state.mobile', :src='result.image', width='200')
</template>

<script>
import results from '@/data/results.js'

export default {
  name: 'Result',
  data() {
    return {
      results: results,
    }
  },
  computed: {
    result() {
      const defaultResult = 'a'
      return this.results[this.$store.state.result ? this.$store.state.result : defaultResult]
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-result
  +background((url(~@/assets/ball-item-2.png), url(~@/assets/ball-item-3.png), url(~@/assets/general-background.jpg)), (80px, 40px, cover), no-repeat, (top calc(50% - 240px) right calc(50% - 120px), right calc(50% - 640px) bottom calc(50% - 200px), center center))
  +flex(null, center)
  +padding-y(100px, 80px)
  &::after
    +position(absolute, 0, 0, 0, 0)
    content: ''
    display: block
    pointer-events: none
  &.result-a
    &::after
      +background((url(~@/assets/result-b-symbol.png), url(~@/assets/result-c-symbol.png)), 108px, no-repeat, (bottom calc(50% - 212px) right calc(50% - 240px), top calc(50% - 200px) right calc(50% - 440px)))
    h3
      color: #F1C21B
  &.result-b
    &::after
      +background((url(~@/assets/result-a-symbol.png), url(~@/assets/result-c-symbol.png)), 108px, no-repeat, (bottom calc(50% - 212px) right calc(50% - 240px), top calc(50% - 200px) right calc(50% - 440px)))
    h3
      color: #6FDC8C
  &.result-c
    &::after
      +background((url(~@/assets/result-b-symbol.png), url(~@/assets/result-a-symbol.png)), 108px, no-repeat, (bottom calc(50% - 212px) right calc(50% - 240px), top calc(50% - 200px) right calc(50% - 440px)))
    h3
      color: #FF8314
.container
  align-items: center
  display: grid
  grid-gap: 240px
  grid-template-columns: 1fr auto
label
  +font(24px, white, null, .05em, bold)
  display: block
  margin-bottom: 28px
img
  margin-right: 120px
h3
  +font(36px, null, null, .05em, bold)
  margin-bottom: 60px
.description
  +font(20px, white, 1.5, .05em, 500, justify)
  margin-bottom: 80px
.whitebook
  +background(url(~@/assets/whitebook-background.png))
  +flex(center, center)
  +ifont(20px, #455CFF, null, .05em, 500)
  +size(345px, 66px)
  margin-bottom: 16px
  &:hover
    text-decoration: none
.contact
  +font(14px, #FEFEFE, null, .05em)
  a
    color: #A7F0BA

@media (max-width: 1023px)
  #v-result
    +background(url(~@/assets/general-background.jpg), cover, no-repeat, center center)
    +padding-y(80px, 100px)
    &::after
      display: none
  .container
    display: block
  label
    font-size: 16px
    margin-bottom: 24px
  img
    +margin-x(auto)
    display: block
    margin-bottom: 40px
  h3
    font-size: 24px
    margin-bottom: 12px
  .description
    font-size: 16px
    margin-bottom: 28px
  .whitebook
    +margin-x(auto)
    +size(248px, 48px)
    font-size: 14px
  .contact
    +font(12px, null, 1.5, null, null, center)
</style>
