export default {
  a: {
    id: 'a',
    name: '混合多雲領先派',
    thumb: require('@/assets/result-a-thumb.png'),
    image: require('@/assets/result-a-symbol.png'),
    description: '您的公司看起來願意擁抱數位趨勢，在混合雲架構上的布署已有相關規劃，建議持續強化資料安全性及跨雲資料的移動管理，IBM 伺服器更能讓您的資料在練功過程更加穩定！',
    link: 'https://drive.google.com/drive/folders/1iWUMV3Me2-quQbFJSAMUIQobsJmvpEW3?usp=sharing',
  },
  b: {
    id: 'b',
    name: '逐步轉型傳承派',
    thumb: require('@/assets/result-b-thumb.png'),
    image: require('@/assets/result-b-symbol.png'),
    description: '您的公司看起來對數位轉型有初步計畫，建議您從不符合效益的伺服器開始升級汰換，IBM 伺服器不但能降低維運成本，還能協助企業未來進一步完成混合雲架構。',
    link: 'https://drive.google.com/drive/folders/1YcLpeGGfjq7SBtVGy0nyxzJjG9b1ZWWA?usp=sharing',
  },
  c: {
    id: 'c',
    name: '保險謹慎地端派',
    thumb: require('@/assets/result-c-thumb.png'),
    image: require('@/assets/result-c-symbol.png'),
    description: '您的公司看起來較為謹慎小心，IBM 建議可踏出擁抱混合雲數位趨勢的第一步。先從伺服器的升級汰換開始，除了更利於資料處理效能，也能為企業未來進一步的數位轉型做好基礎。',
    link: 'https://drive.google.com/drive/folders/1NnEmyRcowJXnKTZcovlzxOFgKxZCk73g?usp=sharing',
  },
}
