<template lang="pug">
#v-result-dimmer(@click.self='$emit("close")')
  transition(name='draw', appear): .card(:class='`result-${$store.state.result}`')
    label
      | 測驗結果如下
      br
      span 你的公司屬於
    img(:src='result.thumb', :alt='result.name', :width='$store.state.mobile ? 90 : 108')
    strong {{ result.name }}
    button(@click='$emit("close")')
      | 填寫資料
      br
      | 獲得武林秘笈白皮書
</template>

<script>
import results from '@/data/results.js'

export default {
  name: 'ResultDimmer',
  data() {
    return {
      results: results,
    }
  },
  computed: {
    result() {
      return this.results[this.$store.state.result]
    },
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

.draw-enter-active
  transition: opacity .4s .4s, transform .4s .4s
.draw-enter
  opacity: 0
  transform: translateY(-20px)

#v-result-dimmer
  +flex(null, center)
  +position(fixed, 0, 0, 0, 0)
  background-color: rgba(black, .6)
  z-index: 99
.card
  +margin-x(auto)
  +size(300px, 440px)
  background-color: white
  padding-top: 40px
  &.result-a
    +border(12px, #F1C21B, 16px)
    button
      +background(url(~@/assets/whitebook-a-background.png), )
      color: #F1C21B
  &.result-b
    +border(12px, #6FDC8C, 16px)
    button
      +background(url(~@/assets/whitebook-b-background.png))
      color: #6FDC8C
  &.result-c
    +border(12px, #FF8314, 16px)
    button
      +background(url(~@/assets/whitebook-c-background.png))
      color: #FF8314
label
  +font(16px, black, 1.5, .05em, null, center)
  display: block
  margin-bottom: 20px
  span
    +font(18px, null, null, null, bold)
img
  +margin-x(auto)
  display: block
  margin-bottom: 20px
strong
  +font(24px, black, 1.5, .05em, bold, center)
  display: block
  margin-bottom: 32px
button
  +ifont(16px, null, 1.5, .05em, 500, center)
  +margin-x(auto)
  +size(244px, 66px)
  display: block
</style>
