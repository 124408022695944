<template lang="pug">
#v-app
  v-header
  transition(name='route', mode='out-in'): router-view
  v-footer
  transition(name='fade', appear): v-result-dimmer(v-if='$route.name === "form" && $store.state.result !== "" && dimmer', @close='dimmer = false')
</template>

<script>
import VHeader from '@/components/Header.vue'
import VFooter from '@/components/Footer.vue'
import VResultDimmer from '@/components/ResultDimmer.vue'

export default {
  name: 'App',
  components: {
    VHeader,
    VFooter,
    VResultDimmer,
  },
  data() {
    return {
      dimmer: true,
    }
  },
  created() {
    document.cookie=`XSRF-TOKEN=${Math.random().toString(36).substr(2)}`
    $(window).resize(() => {
      const mobile = $(window).width() < 1020
      if(this.$store.state.mobile !== mobile) {
        if(mobile) {
          this.$store.commit('setMobile', true)
        } else {
          this.$store.commit('setMobile', false)
        }
      }
    }).resize()
    this.$store.commit('saveUtm', this.$route.query)
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      if(to.name !== 'from') { this.dimmer = true }
    })
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.route-enter-active, .route-leave-active
  transition: opacity .5s
.route-enter, .route-leave-to
  opacity: 0
.fade-enter-active, .fade-leave-active
  transition: opacity .4s .2s
.fade-enter, .fade-leave-to
  opacity: 0


#v-app
  display: grid
  grid-template-rows: 1fr
  margin-left: auto
  margin-right: auto
  min-height: 100%
  min-width: 360px
  position: relative

@media (max-width: 1019px)
  #v-app
    max-width: 600px
</style>
