<template lang="pug">
section#v-question: .container
  .question(v-for='(question, i) of questionStack')
    .card(:style='cardStyles[i]')
      img(:src='question.image', :width='$store.state.mobile ? 80 : 108')
      label Q{{ question.id }}
      p {{ question.content }}
  button.yes
    span YES
    label {{ $store.state.mobile ? '' : '點選或左右拖曳' }}
  button.no
    span NO
    label {{ $store.state.mobile ? '' : '點選或左右拖曳' }}
  label.progress {{ progress }}
</template>

<script>
import questions from '@/data/questions'
import { addIndex, append, min, reduce, reverse } from 'ramda'
import { merge, fromEvent } from 'rxjs'
import { map, concatMap, tap, takeUntil } from 'rxjs/operators'

export default {
  name: 'Question',
  data() {
    return {
      questions: questions,
      topCardIndex: questions.length - 1,
      topCardTransition: false,
      cardsOffset: Array(questions.length).fill(0),
      answers: [],
    }
  },
  computed: {
    questionStack() {
      return reverse(this.questions)
    },
    cardStyles() {
      return this.cardsOffset.map((cardOffset, i) => ({
        pointerEvents: i === this.topCardIndex ? 'auto' : null,
        transform: `translateX(${cardOffset}px) rotate(${60*cardOffset/$(window).width()/2}deg)`,
        transition: (i === this.topCardIndex || i === this.topCardIndex + 1) && this.topCardTransition ? 'opacity .8s, transform .4s linear' : null,
        opacity: i > this.topCardIndex ? 0 : null
      }))
    },
    progress() {
      return `${min(this.questions.length - this.topCardIndex, this.questions.length)}/${this.questions.length}`
    },
  },
  methods: {
    answer(questionId, option) {
      if (option === 'YES') {
        this.$set(this.cardsOffset, this.topCardIndex, 1.1 * $(window).width())
        this.answers = append('YES', this.answers)
      } else if (option === 'NO') {
        this.$set(this.cardsOffset, this.topCardIndex, -1.1 * $(window).width())
        this.answers = append('NO', this.answers)
      }

      if(this.topCardIndex === 0) {
        this.$store.commit('setAnswers', this.answers)
        this.$store.commit('setResult', this.getResult(this.answers))
      }
      this.topCardIndex--
    },
    getResult(answers) {
      const reduceIndexed = addIndex(reduce)
      const points = reduceIndexed((points, answer, i) => points + this.questions[i][answer.toLowerCase()], 0, answers)
      return points < 3 ? 'c' :
        points < 7 ? 'b' : 'a'
    },
  },
  mounted() {
    const finish = () => { setTimeout(() => { this.$router.push({ name: 'form' }) }, 100) }
    const mouseMove$ = fromEvent(document, 'mousemove').pipe(
      tap(mouseEvent => mouseEvent.preventDefault()),
      map(mouseEvent => mouseEvent.clientX),
    )
    const touchMove$ = fromEvent(document, 'touchmove').pipe(
      map(touchEvent => touchEvent.changedTouches[0].clientX),
    )
    const mouseUp$ = fromEvent(document, 'mouseup')
    const touchEnd$ = fromEvent(document, 'touchend')
    const move$ = merge(mouseMove$, touchMove$)
    const end$ = merge(mouseUp$, touchEnd$)

    let topCardSubscription
    const subscribeTopCard = () => {
      const topCard = $('#v-question .card')[this.topCardIndex]
      const mouseDown$ = fromEvent(topCard, 'mousedown').pipe(
        tap(mouseEvent => mouseEvent.preventDefault()),
        map(mouseEvent => mouseEvent.clientX),
      )
      const touchStart$ = fromEvent(topCard, 'touchstart').pipe(
        tap(touchEvent => touchEvent.preventDefault()),
        map(touchEvent => touchEvent.changedTouches[0].clientX)
      )
      const start$ = merge(mouseDown$, touchStart$)

      topCardSubscription = start$.pipe(
        tap(() => this.topCardTransition = false),
        concatMap(start => {
          return move$.pipe(
            takeUntil(end$),
            map(move => move - start),
            tap({ complete: () => {
              this.topCardTransition = true
              if (Math.abs(this.cardsOffset[this.topCardIndex]) >= 100) {
                const questionId = this.questions.length - this.topCardIndex
                this.cardsOffset[this.topCardIndex] > 0 ? this.answer(questionId, 'YES') : this.answer(questionId, 'NO')
                topCardSubscription.unsubscribe()
                this.topCardIndex >= 0 ? subscribeTopCard() : finish()
              } else {
                this.$set(this.cardsOffset, this.topCardIndex, 0)
              }
            }}),
          )
        }),
      ).subscribe(offset => this.$set(this.cardsOffset, this.topCardIndex, offset))
    }
    subscribeTopCard()

    const yes = $('#v-question .yes')[0]
    const no = $('#v-question .no')[0]
    const yes$ = fromEvent(yes, 'click')
    const no$ = fromEvent(no, 'click')
    yes$.subscribe(() => {
      this.topCardTransition = true
      const questionId = this.questions.length - this.topCardIndex
      this.answer(questionId, 'YES')
      topCardSubscription.unsubscribe()
      this.topCardIndex >= 0 ? subscribeTopCard() : finish()
    })
    no$.subscribe(() => {
      this.topCardTransition = true
      const questionId = this.questions.length - this.topCardIndex
      this.answer(questionId, 'NO')
      topCardSubscription.unsubscribe()
      this.topCardIndex >= 0 ? subscribeTopCard() : finish()
    })
  },
}
</script>

<style lang="sass" scoped>
@import '~@/mixin.sass'

#v-question
  +background(url(~@/assets/general-background.jpg))
  +flex(null, center)
  +padding-y(100px, 80px)
  overflow: hidden
*
  pointer-events: none
.container
  height: 520px
  position: relative
.question
  +position(absolute, calc(50% - 500px / 2), null, null, calc(50% - 340px / 2))
  z-index: 1
  &:nth-child(3n)
    transform: rotate(5deg)
    & .card
      +border(2px, #6FDC8C, 16px)
      background-color: rgba(white, .6)
      box-shadow: 0 0 8px #6FDC8C
      &::before
        +border(2px, #6FDC8C, 6px)
        box-shadow: 0 0 8px #6FDC8C
  &:nth-child(3n + 1)
    transform: rotate(10deg)
    & .card
      +border(2px, #FF8314, 16px)
      background-color: rgba(white, .6)
      box-shadow: 0 0 8px #FF8314
      &::before
        +border(2px, #FF8314, 6px)
        box-shadow: 0 0 8px #FF8314
  &:nth-child(3n + 2)
    & .card
      +border(2px, #F1C21B, 16px)
      background-color: rgba(white, .6)
      box-shadow: 0 0 8px #F1C21B
      &::before
        +border(2px, #F1C21B, 6px)
        box-shadow: 0 0 8px #F1C21B
.card
  +size(300px, 440px)
  cursor: pointer
  padding: 72px 32px 0
  pointer-events: auto
  position: relative
  &::before
    +position(absolute, 12px, 12px, 12px, 12px)
    background-color: white
    content: ''
    display: block
  *
    position: relative
  img
    +margin-x(auto)
    display: block
    margin-bottom: 24px
  label
    +font(40px, black, null, null, bold, center)
    +margin-x(auto)
    display: block
    font-family: 'IBM Plex Sans'
    margin-bottom: 24px
  p
    +font(20px, black, 1.5, .02em, bold, justify)
button
  +position(absolute, 180px)
  pointer-events: auto
  span
    +font(90px, white, null, null, bold)
    font-family: 'IBM Plex Sans'
  label
    +font(16px, white, null, .05em)
    +position(absolute, 160px)
    width: max-content
  &.yes
    right: 120px
    label
      left: 0
      &::before
        +border(8px 0 8px 16px, transparent transparent transparent white)
        +position(absolute, -38px, null, null, 140px)
        content: ''
        display: block
      &::after
        +position(absolute, -32px, null, null, 0)
        +size(140px, 4px)
        background-color: white
        content: ''
        display: block
  &.no
    left: 120px
    label
      right: 0
      &::before
        +border(8px 16px 8px 0, transparent white transparent transparent)
        +position(absolute, -38px, 140px)
        content: ''
        display: block
      &::after
        +position(absolute, -32px, 0)
        +size(140px, 4px)
        background-color: white
        content: ''
        display: block
.progress
  +font(24px, white, null, null, bold)
  +position(absolute, null, calc(50% - 230px), 52px)
  font-family: 'IBM Plex Sans'

@media (max-width: 1023px)
  #v-question
    +padding-y(80px, 100px)
  .container
    height: 400px
  .question
    left: calc(50% - 230px / 2)
    top: calc(50% - 320px / 2)
  .card
    +size(230px, 330px)
    padding: 52px 28px 0
    img
      margin-bottom: 20px
    label
      font-size: 32px
      margin-bottom: 18px
    p
      font-size: 16px
  button
    top: 150px
    z-index: 1
    span
      +circle(80px)
      +flex(center, center)
      background: radial-gradient(50% 50% at 50% 50%, #455CFF 0%, rgba(255, 255, 255, 0) 100%), rgba(#FEFEFE, .7)
      font-size: 28px
    &.yes
      right: calc(50% - 155px)
      label
        +position(null, 64px, null, null, 20px)
        &::before
          +position(null, -10px, null, null, 40px)
          border-width: 2px 0 2px 4px
          transform: translateY(0.5px)
        &::after
          +position(null, -8px, null, null, 0)
          +size(40px, 1px)
    &.no
      left: calc(50% - 155px)
      label
        +position(null, 64px, 20px)
        &::before
          +position(null, -10px, 40px)
          border-width: 2px 4px 2px 0
          transform: translateY(0.5px)
        &::after
          +position(null, -8px, 0)
          +size(40px, 1px)
  .progress
    bottom: 0
    font-size: 14px
    right: calc(50% - 150px)
</style>
