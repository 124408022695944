import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import VHome from '@/views/Home.vue'
import VQuestion from '@/views/Question.vue'
import VForm from '@/views/Form.vue'
import VResult from '@/views/Result.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, saved) {
    return new Promise(resolve => {
      const position = saved ? saved : { x: 0, y: 0 }
      setTimeout(() => resolve(position), 500)
    })
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: VHome,
    },
    {
      path: '/question',
      name: 'question',
      component: VQuestion,
    },
    {
      path: '/form',
      name: 'form',
      component: VForm,
      beforeEnter(to, from, next) {
        store.state.result === '' ? next({ name: 'question' }) : next()
      }
    },
    {
      path: '/result',
      name: 'result',
      component: VResult,
    },
    {
      path: '*',
      redirect: '/',
    },
  ]
})
