import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobile: null,
    utm: {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
    },
    answers: [],
    result: '',
  },
  mutations: {
    saveUtm(state, query) {
      state.utm.utm_source = query.utm_source ? query.utm_source : null
      state.utm.utm_medium = query.utm_medium ? query.utm_medium : null
      state.utm.utm_campaign = query.utm_campaign ? query.utm_campaign : null
      state.utm.utm_term = query.utm_term ? query.utm_term : null
      state.utm.utm_content = query.utm_content ? query.utm_content : null
    },
    setMobile(state, mobile) {
      state.mobile = mobile
    },
    setAnswers(state, answers) {
      state.answers = answers
    },
    setResult(state, result) {
      state.result = result
    },
  },
})
